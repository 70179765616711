<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'
               :destroy-on-close='true' :close-on-click-modal='false' width='1000px'>
      <el-form :model='formData' size='medium' :rules='rules' ref='ruleForm' label-width='120px'>
        <el-row>
          <el-col :span='8'>
            <el-form-item label='红人昵称' prop='nickname'>
              <span v-if='formData.id'>{{ formData.nickname }}</span>
              <ArtistSearch :name.sync='formData.nickname' @handleSelect='selectArtist' style='display: inline'
                            v-else />
              <!--          &nbsp;&nbsp;<el-button type='text' icon='el-icon-edit'></el-button>-->
            </el-form-item>
          </el-col>
          <el-col :span='8'>
            <el-form-item label='平台' prop='platform_code'>
              <span v-if='formData.id'>{{ formData.platform_name }}</span>
              <el-select v-else v-model='formData.platform_code' @change='changePlatform'>
                <el-option v-for='(platform,index) in artist_platforms' :key='index'
                           :value='platform.code'
                           :label='platform.platform_name'
                >
                  <span>{{ platform.platform_name }}</span>
                  <span style='float: right;font-size: 0.8em;'>{{ platform.account_id || '-' }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span='8'>
            <el-form-item label='平台ID'>
              <span>{{ formData.platform_account_id || '' }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label='开播时间' prop='timeRange'>
          <el-date-picker
            v-model='formData.timeRange'
            type='datetimerange'
            align='right'
            range-separator='~'
            start-placeholder='开始日期'
            end-placeholder='结束日期'
            format='yyyy年MM月dd日 HH时mm分'
            value-format='yyyy-MM-dd HH:mm'
            :default-time="['12:00:00', '14:00:00']" style='width: 500px'>
          </el-date-picker>
        </el-form-item>
        <el-form-item label='直播主题' prop='theme'>
          <el-input v-model='formData.theme' placeholder='直播主题' clearable show-word-limit maxlength='200' />
        </el-form-item>
        <el-form-item label='发起人' prop='leader_id'>
          <div style='display: flex'>
            <div style='display: inline-block'>
              <span :title='`${formData.leader_id}`'>{{ formData.leader_name }}</span>
              &nbsp;
              &nbsp;
              <el-button type='text' icon='el-icon-edit' @click='changeLeader'
                         v-if='userPermissions.indexOf("lb_schedule_edit_leader")>-1'></el-button>
            </div>
            <div style='display: inline-block'>
              &nbsp;
              &nbsp;
              <UserSelect :keyword='formData.leader_name'
                          @selected='handleUserSelect' v-if='editLeader' style='display: inline-block' />
            </div>
          </div>
        </el-form-item>
        <!--        <el-form-item label='货品结构'>-->
        <!--          <el-row>-->
        <!--            <el-col :span='14'>-->
        <!--              <el-input type='textarea' v-model='formData.product_structure' placeholder='护肤+彩妆+女装鞋包+生活日用+福利品'-->
        <!--                        :autosize='{minRows:3,maxRows:8}' show-word-limit maxlength='200' clearable></el-input>-->
        <!--            </el-col>-->
        <!--            <el-col :span='8'>-->
        <!--              <div style='display: flex;padding: 10px'>-->
        <!--                <el-tag @click='appendTag(option)' type='primary' size='small' effect='plain'-->
        <!--                        v-for='(option,key) in product_structure_options' :key='key' style='margin-right: 10px'>-->
        <!--                  <el-link type='primary'>{{ option }}</el-link>-->
        <!--                </el-tag>-->
        <!--              </div>-->
        <!--            </el-col>-->
        <!--          </el-row>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label='最晚初选时间' prop='deadline'>-->
        <!--          <el-date-picker-->
        <!--            v-model='formData.deadline'-->
        <!--            type='date'-->
        <!--            format='yyyy年MM月dd日'-->
        <!--            value-format='yyyy-MM-dd'-->
        <!--            placeholder='最晚选品初选时间'>-->
        <!--          </el-date-picker>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label='坑位数量'>-->
        <!--          <el-input placeholder='例如：40-70' v-model='formData.pit_num' clearable show-word-limit-->
        <!--                    maxlength='50'></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label='备注'>
          <el-input type='textarea' v-model='formData.remark'
                    :placeholder='`1.新品超20个及已上需准备ppt \n2.每个小类目尽量准备2个及以上方案 \n3.各品牌如有新品可先提报 \n4. 美垂类产品需准备美修截图`'
                    :autosize='{minRows:5,maxRows:10}' show-word-limit maxlength='500' clearable></el-input>
        </el-form-item>
        <!--        <el-form-item v-if='formData.id'>-->
        <!--          <el-button type='text' @click='showNotify=!showNotify'>消息通知-->
        <!--            <span :class="[showNotify?'el-icon-arrow-up':'el-icon-arrow-down']"></span>-->
        <!--          </el-button>-->
        <!--        </el-form-item>-->
      </el-form>
      <div style='text-align: center'>
        <el-button type='primary' size='medium' @click='handleConfirm("ruleForm")' :disabled='loadingCommit'
                   :loading='loadingCommit' v-if='canSave'>
          {{ formData.id ? '保存' : '添加' }}
        </el-button>
        <el-button @click='close' size='medium'>取消</el-button>
      </div>
      <!--      <el-dialog-->
      <!--        width='800px'-->
      <!--        title='钉钉消息通知'-->
      <!--        :visible.sync='showNotify'-->
      <!--        append-to-body-->
      <!--      >-->
      <!--        <NotifySend :id='formData.id' :action.sync='action' @close='close' />-->
      <!--      </el-dialog>-->
    </el-dialog>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import PlatformSelect from '@/pages/platform/components/PlatformSelect'
import { mapGetters } from 'vuex'
import DeptTree from '@/pages/departments/components/DeptTree'
import NotifySend from '@/pages/lb/NotifySend'
import UserSelect from '@/pages/lb/components/UserSelect'

export default {
  name: 'CreateEdit',
  components: { UserSelect, NotifySend, DeptTree, PlatformSelect, ArtistSearch },
  props: {
    id: {
      type: [Number, String]
    },
    defaultFormData: {
      type: Object,
      default() {
        return {
          artist_id: null,
          nickname: null,
          platform_code: null,
          timeRange: null,
          start_at: null,
          end_at: null,
          theme: null,
          leader_id: null,
          leader_name: null,
          pit_num: null,
          product_structure: null,
          remark: null,
          deadline: null,
          platform_account_id: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `${this.id ? '修改' : '添加'}直播排期`
    },
    canSave() {

      return this.formData.id ? this.userPermissions.indexOf('lb_live_edit') > -1 : this.userPermissions.indexOf('lb_live_create') > -1
    }
  },
  data() {
    return {
      loadingCommit: false,
      loadingNotify: false,
      action: 'add',
      editLeader: false,
      formData: {
        type: 2,//直播定品
        artist_id: null,
        nickname: null,
        platform_code: null,
        timeRange: null,
        start_at: null,
        end_at: null,
        theme: null,
        leader_id: null,
        leader_name: null,
        pit_num: null,
        product_structure: null,
        remark: null,
        deadline: null,
        platform_account_id: null
      },
      lbTimeRange: [],
      rules: {
        nickname: { required: true, message: '请选择红人（KOL）', trigger: 'change' },
        platform_code: { required: true, message: '请选择平台', trigger: 'change' },
        timeRange: { required: true, message: '请选择开播时间范围', trigger: 'change' },
        theme: { required: true, message: '直播主题必填', trigger: 'change' },
        deadline: { required: true, message: '最晚初选时间必填', trigger: 'change' },
        leader_id: { required: true, message: '请选择发起人', trigger: 'change' }
      },
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      options: [],
      showNotify: false,
      product_structure_options: [
        '护肤',
        '彩妆',
        '女装鞋包',
        '生活日用',
        '福利品'
      ],
      accountData: [],
      artist_platforms: []

    }
  },
  methods: {
    onOpen() {
      this.action = 'add'
      this.editLeader = false

      // Object.assign(this.formData, this.defaultFormData)
      // console.log('defaultFormData',this.defaultFormData)
      if (this.id) {
        this.action = 'edit'
        this.getInfo(this.id)
      } else {
        this.formData = {
          type: 2,//直播定品
          artist_id: null,
          nickname: null,
          platform_code: null,
          timeRange: null,
          start_at: null,
          end_at: null,
          theme: null,
          leader_id: null,
          leader_name: null,
          pit_num: null,
          product_structure: null,
          remark: null,
          deadline: null
        }
        Object.assign(this.formData, this.defaultFormData)
        this.loadingNotify = false
        let userInfo = this.$store.state.userInfo
        this.formData.leader_id = userInfo.id
        this.formData.leader_name = userInfo.name || userInfo.nickname
      }
    },
    onClose() {
      this.formData = {}
      // this.$emit('')
    },
    close() {
      this.showNotify = false
      this.$emit('update:visible', false)
    },
    changeLeader() {
      this.editLeader = !this.editLeader
      this.formData.leader_name
    },
    async getInfo(id) {
      let { info } = await this.$api.getLbScheduleInfo(id)
      this.$nextTick(() => {
        //时间格式的转换前端处理更好，维护起来方便
        // info.timeRange = [this.$utils.parseTime(info.start_at,'{y}-{m}-{d} {h}:{i}'), this.$utils.parseTime(info.end_at,'{y}-{m}-{d} {h}:{i}')]
        info.timeRange = [info.start_at, info.end_at]
        this.formData = info
      })
    },
    async selectArtist(row) {
      this.formData.platform_code = null
      if (row) {
        this.formData.artist_id = row.id || ''
        this.formData.nickname = row.nickname || ''

        let { artist_platforms } = await this.$api.getArtistPlatforms(this.formData.artist_id)
        this.artist_platforms = artist_platforms || []
      } else {
        this.formData.artist_id = null
        this.formData.nickname = null
        this.artist_platforms = []
      }

    },
    async handleConfirm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //
          if (this.formData.artist_id) {
            this.loadingCommit = true
            this.formData.start_at = this.formData.timeRange[0]
            this.formData.end_at = this.formData.timeRange[1]
            if (!this.formData.type) {
              this.msgWarn('排期类型未知，无法创建，请联系管理员！！！')
              return false
            }
            let info = await this.$api.saveLbSchedule(this.formData)
            if (info) {
              if (this.formData.id)
                this.msgSuccess('保存成功')
              else
                this.msgSuccess('添加成功')

              setTimeout(() => {
                this.loadingCommit = false
                this.close()
              }, 500)
            }
          } else {
            this.msgWarn('请选择库中红人！')
            return false
          }
        } else {
          console.log('error submit!!')
          this.msgWarn('请填写必填项')
          return false
        }
      })
    },
    changePlatform(val) {
      this.formData.platform_code = val
      if (this.artist_platforms.length > 0) {
        let data = this.artist_platforms.find(value => value.code === this.formData.platform_code)
        this.formData.platform_account_id = data?.account_id || '/'
      }
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.leader_id = item.value
      this.formData.leader_name = item.label
      this.editLeader = false
    },
    appendTag(option) {
      if (this.formData.product_structure == null || this.formData.product_structure == '')
        this.formData.product_structure = option
      else
        this.formData.product_structure += '+' + option
    }

  }
}
</script>

<style scoped>
.form-card {
  border: #DCDFE6 1px solid;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}


</style>
